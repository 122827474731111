<template>
  <transition name="fade" :css="true">
    <div class="popup" :class="{ opened: visible }" v-show="visible">
      <div class="popup__content" :class="{ contract_doc_list: contractDocs }">
        <div class="popup__head">
          <h5 class="popup__title"><slot name="title" /></h5>
          <v-icon
            class="popup__close"
            :icon="require(`@/assets/svg/exit.svg`)"
            @click="closePopup"
          />
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "v-popup",
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    confirmed: {
      type: Boolean,
      default: () => false,
    },
    contractDocs: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    closePopup(e) {
      if (
        e.target.classList.contains("popup") ||
        e.target.classList.contains("popup__close")
      ) {
        this.$emit("closePopup");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  /* .popup__head */

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  /* .popup__title */

  &__title {
    color: $text-black;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  /* .popup__content */

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    max-width: 335px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }
  /* .popup__close */

  &__close {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      min-width: 40px;
      min-height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #f4f8f9;
      z-index: 1;
    }
  }
}

@media (max-width: 575px) {
  .popup {
    /* .popup__content */

    &__content {
      max-width: 300px;
    }

    /* .popup__head */

    &__head {
    }

    /* .popup__title */

    &__title {
    }

    /* .popup__close */

    &__close {
    }
  }
}

.contract_doc_list {
  height: 14rem;
  overflow: scroll;
}
</style>

<style lang="scss">
.popup {
  &__close {
    svg {
      position: relative;
      z-index: 2;
      pointer-events: none;
    }
  }
}
</style>
