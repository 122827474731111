import { createStore } from "vuex";
import apiRequest from "../middlewares/api-request";
import signup from "./modules/signup";
import dashboard from "./modules/dashboard";
import notifications from "./modules/notifications";
import axios from "axios";

export default createStore({
  state: {
    test: 24,
    moratorium: {
      status: false,
      end_date: null,
    },
  },
  getters: {
    REGISTRATION_ID(state) {
      return state.test;
    },
    MORATORIUM_STATUS(state) {
      return state.moratorium;
    },
  },
  mutations: {
    SET_MORATORIUM_STATUS: (state, data) => {
      state.moratorium = {
        status: Boolean(data.status),
        end_date: data.end_date,
      };
    },
  },
  actions: {
    async executeRecaptcha({ commit }) {
      try {
        const token = await window.$recaptcha("login");
        throw token;
      } catch (error) {
        throw error;
      }
    },
    async CREATE_DOCUMENTS({}, client) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/documents/store/${client}`,
            null,
            {
              headers: {
                Authorization: "Bearer " + this.getters.TOKEN,
              },
            }
          )
          .then((res) => {
            if (res?.response?.status === 500)
              throw new Error("Ошибка сервера");
            else resolve(res);
          })
          .catch((err) => {
            console.log("124124", err);
            reject(err);
          });
      });
    },
    async RESET_PASSWORD({ commit }, { step, payload }) {
      console.log(step);
      if (step == 0) {
        return new Promise((resolve, reject) => {
          axios
            .post(
              `${process.env.VUE_APP_API_URL}api/clients/password_reset_request`,
              payload
            )
            .then((res) => {
              if (res?.response?.status === 500)
                throw new Error("Ошибка сервера");
              else resolve(res);
            })
            .catch((err) => {
              console.log("124124", err);
              reject(err);
            });
        });
      }
      if (step == 1) {
        return new Promise((resolve, reject) => {
          axios
            .patch(
              `${process.env.VUE_APP_API_URL}api/clients/password_reset_confirm/${payload?.user_id}`,
              payload
            )
            .then((res) => {
              if (res?.response?.status === 500)
                throw new Error("Ошибка сервера");
              else resolve(res);
            })
            .catch((err) => {
              console.log("124124", err);
              reject(err);
            });
        });
      }
      if (step == 2) {
        return new Promise((resolve, reject) => {
          axios
            .patch(
              `${process.env.VUE_APP_API_URL}api/clients/password_reset/${payload?.user_id}`,
              payload
            )
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    },
    async TEST({ commit }, payload) {
      return new Promise((resolve) => {
        const res = axios.get("https://jsonplaceholder.typicode.com/todos/1");

        resolve(res);
      });
    },
    async GET_CLIENT() {
      let res = await apiRequest.function("GET", `/clients`, null, null, null, {
        Authorization: `Bearer ${this.getters.TOKEN}`,
      });
    },
    async CHECK_SMS({ commit }, { method, url, code, id, type = null }) {
      return await apiRequest.function(
        method,
        `api/clients/${id}/${url}`,
        null,
        null,
        { code, type }
      );
    },
    async GET_CLIENT_ID_BY_PHONE({commit},phone) {
      let res = await apiRequest.function("GET", `api/clients/get_client_id_by_phone/${phone}`);
      return res.data.data.client_id;
    },
    async GET_MORATORIUM_STATUS({ commit }, { url = "", client }) {
      let res = await apiRequest.function(
        "GET",
        `api/clients/moratorium${url}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      commit("SET_MORATORIUM_STATUS", res.data.data.data);
      return res.data.data.data;
    },
    async SEND_FEEDBACK({ commit }, payload) {
      let res = await apiRequest.function(
        "POST",
        `api/feedback/store`,
        null,
        null,
        payload
      );
      return res;
    },
    async SEND_GURU_LEADS({ commit }, { query, client_id }) {
      let res = await apiRequest.function(
        "POST",
        `api/clients/gurulead/${client_id}`,
        null,
        null,
        query,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      return res;
    },
    async SEND_EMPTY_GURU_LEADS({ commit }, { client_id }) {
      return await apiRequest.function(
        "GET",
        `api/clients/empty_gurulead/${client_id}`,
        ["SET_ACTIVE_ORDERS"],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async SEND_CONTINUE_CODE({ commit }, client_id) {
      return new Promise((resolve) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/${client_id}/phone-confirmation/request`
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch("addNotification", {
                message: error?.response?.data?.message,
              });
            }
            resolve(error?.response);
          });
      });
    },
    async CHECK_CONTINUE_CODE({ commit }, { client_id, code }) {
      return new Promise((resolve) => {
        axios
          .patch(
            `${process.env.VUE_APP_API_URL}api/clients/${client_id}/phone-confirmation/confirm`,
            {
              code,
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch("addNotification", {
                message: error?.response?.data?.message,
              });
            }
            resolve(error?.response);
          });
      });
    },
    async CHECK_TOKEN({ commit }, token) {
      return new Promise((resolve) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}api/client/check_order/${token}`)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch("addNotification", {
                message: error?.response?.data?.message,
              });
            }
            resolve(error?.response);
          });
      });
    },
  },
  modules: { signup, dashboard, notifications },
});
