<template>
  <footer class="v-footer">
    <div class="v-footer__container">
      <div class="v-footer__license">
        <v-icon
          class="v-footer__license-logo"
          :icon="require(`@/assets/svg/logo.svg`)"
          @click="this.$router.push({ path: '/', hash: '#header' })"
        />
        <p class="v-footer__license-subtitle">
          Копирование текстов и материалов с сайта запрещено! В Государственном
          реестре МФО с 20.06.2014 №651403394005270
        </p>
        <p class="v-footer__license-subtitle">
          © 2017-2024. ООО МКК "Рел-ФинансГрупп". Все права защищены.
        </p>
      </div>
      <div class="v-footer__links">
        <a
          href="#getLoan"
          @click="this.$router.push({ path: '/', hash: '#getLoan' })"
          class="v-footer__links-link"
        >
          Как получить займ
        </a>
        <a
          href="#about"
          class="v-footer__links-link"
          @click="this.$router.push({ path: '/', hash: '#about' })"
        >
          О компании
        </a>
        <!-- <a href="/#conditions" class="v-footer__links-link">Условия займа</a> -->
        <a
          href="#getLoan"
          @click="this.$router.push({ path: '/', hash: '#getLoan' })"
          class="v-footer__links-link"
        >
          Условия займа
        </a>
        <!-- <a
          href="#about"
          class="v-footer__links-link"
          @click="this.$router.push({ path: '/', hash: '#about' })"
        >
          Вопросы и ответы
        </a> -->
      </div>
      <div class="v-footer__contacts">
        <a class="v-footer__contanct" href="mailto:zaimdam@r-fg.ru">
          <span>zaimdam@r-fg.ru</span>
        </a>
        <a
          class="v-footer__contanct"
          href="tel:+79123456789"
          v-show="this.$route?.path !== '/'"
        >
          <span>+7 (912) 345-67-89</span>
        </a>
      </div>
      <div class="v-footer__payments">
        <v-icon
          class="v-footer__payment-icon"
          :icon="require(`@/assets/svg/visa.svg`)"
        />
        <v-icon
          class="v-footer__payment-icon"
          :icon="require(`@/assets/svg/master-card.svg`)"
        />
        <v-icon
          class="v-footer__payment-icon"
          :icon="require(`@/assets/svg/maestro.svg`)"
        />
        <v-icon
          class="v-footer__payment-icon"
          :icon="require(`@/assets/svg/mir.svg`)"
        />
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "v-footer",
  data() {
    return {
      payments: ["visa", "mastercard", "maestro", "mir"],
      publicPath: process.env.BASE_URL,
      charter_dialog: {
        visible: false,
        title: "Уставные документы",
        items: [],
      },
    };
  },
  methods: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.v-footer {
  background-color: $light-blue;
  /* .v-footer__container */

  &__container {
    max-width: $container;
    width: 100%;
    margin: 0 auto;
    padding: 40px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  /* .v-footer__license */

  &__license {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 286px;
    width: 100%;
  }

  /* .v-footer__license-logo */

  &__license-logo {
    position: relative;
    z-index: 2;
    font-size: 36px;
    font-family: "Open Sans", sans-serif;
    font-weight: 900;
    white-space: nowrap;
    color: $accent;
    cursor: pointer;
  }

  /* .v-footer__license-subtitle */

  &__license-subtitle {
    line-height: 130%;
    font-size: 12px;
    color: $text-black;
  }

  /* .v-footer__links */
  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* .v-footer__links-link */
  &__links-link {
    margin-bottom: 10px;
    font-size: 14px;
    color: $text-black;
    text-decoration: none;
    &:last-child {
      margin-bottom: 0;
    }
  }

  /* .v-footer__contacts */

  &__contacts {
    display: flex;
    padding: 12px 0;
    padding-top: 0;
    flex-direction: column;
    color: $white;
  }

  /* .v-footer__contanct */

  &__contanct {
    font-family: "Open Sans";
    margin: 0;
    margin-bottom: 10px;
    margin-right: 50px;
    color: inherit;
    font-weight: 600;
    cursor: pointer;
    span {
      color: $blue;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  /* .v-footer__payments */

  &__payments {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  /* .v-footer__payment-icon */

  &__payment-icon {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.v-footer {
}
@media (max-width: 619px) {
}
@media (max-width: 575px) {
  .v-footer {
    /* .v-footer__container */

    &__container {
    }

    /* .v-footer__logo */

    &__logo {
    }

    /* .v-footer__payment-icon */

    &__payment-icon {
    }

    /* .v-footer__contacts */

    &__contacts {
      padding: 12px 0;
      flex-direction: column;
    }

    /* .v-footer__contanct */

    &__contanct {
    }

    /* .v-footer__license */

    &__license {
    }
  }
}

.feedback__dialog-descr {
  font-size: 13px;
  margin-bottom: 12px;
}
.feedback__dialog-btn {
}
.feedback__dialog-inp {
  margin-bottom: 12px;
}
</style>
