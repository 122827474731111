import apiRequest from "../../middlewares/api-request";
import axios from "axios";
const signup = {
  state: {
    user: {},
    token: "",
    client_id: "",
    active_orders: [],
    history: [],
    documents: [],
    order_documents: [],
    photos: [],
    lk_cards: [],
    prolongation_documents: [],
    contract_documents: [],
    close_loan_documents: [],
    sign_documents: [
      {
        link: `${process.env.VUE_APP_API_URL}documents/static_documents/Правила_предоставления_услуги_быть_в_курсе.pdf`,
        name: "Правила оказания услуги «Будь в курсе!»",
        required: false,
        match: [],
        errorBag: [],
      },
      {
        link: `${process.env.VUE_APP_API_URL}documents/static_documents/Правила_абонентского_обслуживания_Онлайн_консультации_с_врачом.pdf`,
        name: "Правила абонентского обслуживания (Онлайн консультация с врачом) – Телемедицина",
        required: false,
        match: [],
        errorBag: [],
      },
    ],
    admin: false,
  },
  mutations: {
    SET_USER_INFO: async (state, res) => {
      state.user = res.data;
    },
    SET_ACTIVE_ORDERS: (state, res) => {
      if (res?.data?.data?.data?.amount) {
        state.active_orders = [res.data.data.data];
      } else state.active_orders = [];
    },
    SET_HISTORY: (state, res) => {
      res.data.data.data.map((item) => {
        item.time = item.created_at?.split(" ")?.[1];
        const [year, month, day] = item.created_at.split(/[^\d]/),
          str = `${day}.${month}.${year.slice(-2)}`;
        item.created_at = str;
        return item;
      });
      state.history = res.data.data.data;
    },
    SET_DOCUMENTS: (state, res) => {
      let filtred_arr;
      let stop_types = [
        "personal_data_consent",
        "rules_rejection_reason",
        "rejection_reason_application",
        "rules_rejection_reason",
        "asp_agreement",
        "analogue_signature_agreement",
        "service_agreement",
        "loan_granting_rules",
        "grant_application",
        "insurance_application_contract",
        "receive_info_credit_bureau_consent",
      ];

      let test2 = res?.data?.data?.data.map((doc_list) => {
        return doc_list.filter((doc) => !stop_types.includes(doc?.type));
      });
      // console.log(test2);
      // test2.map((item) => {
      //   console.log(item);
      // });
      // let test;
      // test = filtred_arr.sort((a, b) => a.order - b.order);
      state.documents = test2;
    },
    // SET_ORDER_DOCUMENTS: (state, res) => {
    //   state.order_documents = res.data.data.data;
    // },
    CHECK_PHOTO: (state, { id, type, params }) => {
      // let type = params?.[0]?.split('"')?.[3];
      let status = params?.status;
      switch (type) {
        case "selfie":
          type = "Селфи с паспортом";
          break;
        case "passport":
          type = "Первая страница паспорта";

          break;
        case "reg_address":
          type = "Страница регистрации";
          break;
        case "card_photo":
          type = "Фото банковской карты";
          break;
        default:
          break;
      }

      // statusses
      // refuse, pending, accept
      if (!status) {
        status = "pending";
      }

      state.photos.push({
        url: `${process.env.VUE_APP_API_URL}api/files/get/${id}/auth`,
        type,
        id,
        status: status,
        initial_type: params?.type,
      });
      // state.photos.map(item => {
      //   if (item.)
      // })
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_CARDS: (state, cards) => {
      cards?.map((item) => (item.value = false));
      state.lk_cards = cards;
    },
    SET_ADMIN: (state, flag) => {
      state.admin = flag;
    },
    SET_PROLONGATION_DOCUMENTS: (state, docs) => {
      state.prolongation_documents = [];
      if (docs === undefined) return;
      let filtred_docs;
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.type.includes("loan_closing_notice")
        )
        .map((item) => {
          if (
            item?.type === "subscription_service_rules_telejur" ||
            item?.type === "application_for_the_services_of_telejurist"
          )
            item.required = false;
          else if (item.required !== undefined) item.required;
          else item.required = true;

          item.link =
            item.link !== undefined
              ? item.link
              : (item?.preview
                  ? `${process.env.VUE_APP_API_URL}document/preview/prolongation/${item.type}/${item.orderId}`
                  : `${process.env.VUE_APP_API_URL}document/${item?.id}`
                )
          item.name = item?.title ?? item?.name;
          item.match = [];
          item.errorBag = [];
          return item;
        });
      filtred_docs = docsToPush.sort((a, b) => a.order - b.order);
      state.prolongation_documents = filtred_docs;
    },
    SET_CONTRACT_DOCUMENTS: (state, docs) => {
      state.contract_documents = [];
      if (docs === undefined) return;
      let filtred_docs;
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.type.includes("loan_closing_notice") &&
            item?.type !== "rejection_reason_application" &&
            item?.type !== "rules_rejection_reason"
        )
        .map((item) => {
          if (
            item?.type === "subscription_service_rules_doctor" ||
            item?.type === "rules_rejection_reason" ||
            item?.type === "rejection_reason_application" ||
            item?.type === "consultation_with_doctor_application"
          )
            item.required = false;
          else if (item.required !== undefined) item.required;
          else item.required = true;

          item.link =
            item.link !== undefined
              ? item.link
              : (item?.preview
                  ? `${process.env.VUE_APP_API_URL}document/preview/contract/${item.type}/${item.orderId}`
                  : `${process.env.VUE_APP_API_URL}document/${item?.id}`
                )
          item.name = item?.title ?? item?.name;
          item.match = [];
          item.errorBag = [];
          return item;
        });
      filtred_docs = docsToPush.sort((a, b) => a.order - b.order);
      state.contract_documents = filtred_docs;
    },
    SET_CLOSE_LOAN_DOCUMENTS: (state, docs) => {
      state.close_loan_documents = [];
      if (docs === undefined) return;
      let filtred_docs;
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.type.includes("loan_closing_notice")
        )
        .map((item) => {
          if (
            item?.type === "subscription_service_rules_doctor" ||
            item?.type === "consultation_with_doctor_application"
          )
            item.required = false;
          else if (item.required !== undefined) item.required;
          else item.required = true;

          item.link =
            item.link !== undefined
              ? item.link
              : (item?.preview
                  ? `${process.env.VUE_APP_API_URL}document/preview/contract_close/${item.type}/${item.orderId}`
                  : `${process.env.VUE_APP_API_URL}document/${item?.id}`
              )
          item.name = item?.title ?? item?.name;
          item.match = [];
          item.errorBag = [];
          return item;
        });
      filtred_docs = docsToPush.sort((a, b) => a.order - b.order);
      state.close_loan_documents = filtred_docs;
    },
    SET_ORDER_DOCUMENTS: (state, docs) => {
      state.order_documents = [];
      if (docs === undefined) return;
      let filtred_docs;
      const docsToPush = docs
        .filter(
          (item) =>
            !item?.type.includes("insurance_certificate") &&
            !item?.type.includes("loan_closing_notice") &&
            item?.type !== "rules_rejection_reason" &&
            item?.type !== "rejection_reason_application"
        )
        .map((item) => {
          if (
            item?.type === "subscription_service_rules_doctor" ||
            item?.type === "consultation_with_doctor_application" ||
            item?.type === "subscription_service_rules_telejur" ||
            item?.type === "rejection_reason_application"
          )
          {
            item.required = false;
          } else if (item.required !== undefined) {
            item.required;
          } else {
            item.required = true;
          }

          // Если у доки нет ссылки, то добавляем её исходя из того превьюшка это или нет
          if (item.link === undefined) {
            item.link = (item?.preview
              ? `${process.env.VUE_APP_API_URL}document/preview/order/${item.type}/${item.orderId}`
              : `${process.env.VUE_APP_API_URL}document/${item?.id}`
            )
          }

          item.name = item?.title ?? item?.name;
          item.match = [];
          item.errorBag = [];
          return item;
        });
      filtred_docs = docsToPush.sort((a, b) => a.order - b.order);
      state.order_documents = filtred_docs;
    },
  },
  actions: {
    async GET_CLIENT_FROM_SMS_LINK({ commit }, { client, code }) {
      return new Promise((resolve) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}api/clients/${client}/${code}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err?.response);
          });
      });
    },
    async REBID({ commit }, { client_id, period, amount }) {
      const date = new Date();

      const timezoneOffset = date.getTimezoneOffset();

      const timezone = -(timezoneOffset / 60);
      return new Promise((resolve) => {
        let res = axios
          .post(`${process.env.VUE_APP_API_URL}api/clients/confirm`, {
            client_id,
            amount,
            period,
            timezone,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch("addNotification", {
                message: error?.response?.data?.message,
              });
            }
            resolve(error?.response);
          });
      });
    },
    async LOGIN({ commit }, { phone, password, recaptcha_token }) {

      let login_ip = '127.0.0.1';
      await fetch('https://ipapi.co/json/')
        .then(d => d.json())
        .then(d => login_ip = d?.ip);

      return new Promise((resolve) => {
        let res = axios
          .post(`${process.env.VUE_APP_API_URL}api/client/login`, {
            phone,
            password,
            recaptcha_token,
            login_ip
          })
          .then((res) => {
            if (res?.data?.code === 200) {
              commit("SET_TOKEN", res.data.data.token);
            }
            resolve(res);
          })
          .catch((error) => {
            if (error?.response?.status === 422) {
              this.dispatch("addNotification", {
                message: error?.response?.data?.message,
              });
            }
            resolve(error?.response);
          });
      });
    },
    async GET_USER_INFO({ commit }, { url = "", client }) {
      return await apiRequest.function(
        "GET",
        `api/clients/${client}${url}`,
        ["SET_USER_INFO"],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GET_ACTIVE_ORDERS({ commit }, client) {
      return await apiRequest.function(
        "GET",
        `api/clients/${client}/active_order`,
        ["SET_ACTIVE_ORDERS"],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GET_HISTORY({ commit }, client) {
      return await apiRequest.function(
        "GET",
        `api/clients/${client}/credit_history`,
        ["SET_HISTORY"],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GET_DOCUMENTS({ commit }, client) {
      return await apiRequest.function(
        "GET",
        `api/clients/${client}/documents`,
        ["SET_DOCUMENTS"],
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    // async GET_ACTIVE_ORDER_DOCUMENTS({ commit }, client) {
    //   return await apiRequest.function(
    //     "GET",
    //     `api/clients/${client}/active_order_documents`,
    //     ["SET_ORDER_DOCUMENTS"],
    //     null,
    //     null,
    //     { Authorization: `Bearer ${this.getters.TOKEN}` }
    //   );
    // },
    async GET_PHOTOS({ commit }, file) {
      commit("CHECK_PHOTO", file);
    },
    async GET_CARDS({ commit }, client) {
      let res = await apiRequest.function(
        "GET",
        `api/clients/${client}/cards`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      commit("SET_CARDS", res?.data?.data?.cards);
    },
    async PAY_LOAN({ commit }, payload) {
      let link = `api/pay/payment/${payload.contract}`;
      link += `?card_id=${payload.card_id}&type=${payload.type}`;
      if (payload?.prolongation)
        link += `&prolongation=${payload.prolongation}`;

      if (payload?.amount) link += `&amount=${payload?.amount}`;

      const res = await apiRequest.function("GET", link, null, null, payload, {
        Authorization: `Bearer ${this.getters.TOKEN}`,
      });
      return res;
    },
    async SEND_LOAN_PAY_CALLBACK({ commit }, { search, client }) {
      let res = await apiRequest.function(
        "GET",
        `api/pay/callbackPayment/${client}${search}`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
      if (res == undefined) return;
      const { data, code = 400, message } = res.data;
      if (code === 200) {
        // commit("SET_TOKEN", res.data.data.token);
      }
      return res;
    },
    async GET_DASHBOARD_BIND_CARD_LINK({ commit }, client) {
      return await apiRequest.function(
        "GET",
        `api/pay/link/auth`,
        null,
        null,
        null,
        { Authorization: `Bearer ${this.getters.TOKEN}` }
      );
    },
    async GENERATE_ASP_CODE({}, { client_id, asp_type, order_id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/generate_asp`,
            {
              asp_type,
              order_id,
              client_id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.getters.TOKEN,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    async SIGN_ASP_CODE({}, { asp_code, order_id, route }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/${route}`,
            {
              asp_code,
              order_id,
            },
            {
              headers: {
                Authorization: "Bearer " + this.getters.TOKEN,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    async PROLONGATION_SIGN({}, { order_id, asp_code }) {
      return new Promise((resolve) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/prolongation`,
            { order_id, asp_code },
            {
              headers: {
                Authorization: "Bearer " + this.getters.TOKEN,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async GET_UNSIGNED_DOCS({ commit }, client) {
      return new Promise((resolve) => {
        axios
          .get(
            `${process.env.VUE_APP_API_URL}api/clients/${client}/preview_documents`,
            {
              headers: {
                Authorization: "Bearer " + this.getters.TOKEN,
              },
            }
          )
          .then((res) => {
            const allDocs = res?.data?.data?.data;
            console.log("allDocs", allDocs);
            commit("SET_PROLONGATION_DOCUMENTS", allDocs.PROLONGATION);
            commit("SET_CONTRACT_DOCUMENTS", allDocs.CONTRACT);
            commit("SET_CLOSE_LOAN_DOCUMENTS", allDocs.CONTRACT_CLOSE);
            commit("SET_ORDER_DOCUMENTS", allDocs?.ORDER || []);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },
  },
  getters: {
    PROLONGATION_DOCUMENTS(state) {
      return state.prolongation_documents;
    },
    CONTRACT_DOCUMENTS(state) {
      return state.contract_documents;
    },
    CLOSE_LOAN_DOCUMENTS(state) {
      return state.close_loan_documents;
    },
    USER(state) {
      return state.user;
    },
    ACTIVE_ORDERS(state) {
      return state.active_orders;
    },
    HISTORY(state) {
      return state.history;
    },
    DOCUMENTS(state) {
      return state.documents;
    },
    ORDER_DOCUMENTS(state) {
      return state.order_documents;
    },
    PHOTOS(state) {
      return state.photos;
    },
    TOKEN(state) {
      return state.token;
    },
    LK_CARDS(state) {
      return state.lk_cards;
    },
    ADMIN(state) {
      return state.admin;
    },
    SIGN_DOCUMENTS(state) {
      return state.sign_documents;
    },
  },
};

export default signup;
